import { render, staticRenderFns } from "./epoch_stats_component_template.vue?vue&type=template&id=6543ac6a"
import script from "./epoch_stats_component_template.vue?vue&type=script&lang=js"
export * from "./epoch_stats_component_template.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports